<template>
  <section class="hero is-fullheight" id="landing">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered is-desktop">
          <div
            v-scrollAnimation
            class="
              column
              has-text-centered
              is-10-widescreen is-12-desktop
              hero-content
            "
          >
            <h1 class="title is-1 is-size-3-touch has-text-gray">
              Software and Apps <br />
              made in Mannheim.
            </h1>

            <h1 class="subtitle is-3 is-size-5-touch has-text-grey">
              Wir digitalisieren Prozesse und Unternehmen.
            </h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Landing",
};
</script>

<style>
.section-spacer {
  margin-top: 6.9rem;
}

.hero-content {
  transition-delay: 0.31s !important;
}
</style>

<style scoped>
.fade-in {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1000ms cubic-bezier(0, 0.69, 0, 1);
}

.fade-in.appear {
  opacity: 1;
  transform: translateY(0px);
}
</style>
