<template>
  <b-navbar fixed-top spaced shadow>
    <template #brand>
      <b-navbar-item>
        <router-link to="/">
          <img src="@/assets/processed/logo.webp" alt="StraightAndCurved Logo" />
        </router-link>
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item class="is-size-5" tag="router-link" to="/#landing">Home</b-navbar-item>
      <b-navbar-item class="is-size-5" tag="router-link" to="/#about">About us </b-navbar-item>
      <b-navbar-item class="is-size-5" tag="router-link" to="/#team">Team</b-navbar-item>
      <b-navbar-item class="is-size-5" tag="a" :href="portfolioUrl">Portfolio</b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item tag="router-link" to="/prices">Preise </b-navbar-item>
      <b-navbar-item tag="router-link" to="/contact">Kontakt </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      portfolioUrl: process.env.VUE_APP_PORTFOLIO_URL
    }
  }
};
</script>
