const animatedScrollObserver = new IntersectionObserver(((
  entries,
) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('appear');
      animatedScrollObserver.unobserve(entry.target);
    }
  });
}));

export default {
  bind(el) {
    el.classList.add('fade-in');
    animatedScrollObserver.observe(el);
  },
};
