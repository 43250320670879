import Vue from 'vue';

// Style Imports
import {
  FontAwesomeIcon,
} from '@fortawesome/vue-fontawesome';
import {
  library,
} from '@fortawesome/fontawesome-svg-core';

import {
  fab,
} from '@fortawesome/free-brands-svg-icons';

import { fas } from '@fortawesome/free-solid-svg-icons';


import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

import App from './App.vue';
import router from './router';
import ScrollAnimation from './directives/scroll-animation';


library.add(fab);
library.add(fas);

Vue.use(Buefy);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.directive('scrollAnimation', ScrollAnimation);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
