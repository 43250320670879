<template>
  <div class="home">
    <Landing />
    <About />
    <Team />
  </div>
</template>

<script>
import Landing from '@/components/Landing.vue';
import About from '@/components/About.vue';
import Team from '@/components/Team.vue';

export default {
  name: 'Home',
  components: {
    Landing,
    About,
    Team,
  },
};
</script>
