<template>
  <div id="team">
    <section class="section-spacer"></section>
    <div class="section-team diagonal-box">
      <section class="section">
        <div v-scrollAnimation class="container content-team">
          <h2 class="title is-2 is-size-3-touch has-text-centered">
            Unser Team
          </h2>
          <p
            class="
              subtitle
              is-4 is-size-5-touch
              has-text-gray-3 has-text-centered
            "
          >
            Das Team hinter Straight and Curved.
          </p>
          <div class="columns is-multiline is-centered">
            <TeamCard
              name="Tristan Emig"
              role="Geschäftsführer | Co-Founder"
              workRole="Frontend Developer"
              image="team-t-e.webp"
            />
            <TeamCard
              name="Anton Ochel"
              role="Geschäftsführer | Co-Founder"
              workRole="Frontend Developer"
              image="team-a-o.webp"
            />
          </div>
          <div class="columns is-multiline is-centered">
            <TeamCard
              name="Benno Grimm"
              role="Co-Founder"
              workRole="Backend Developer"
              image="team-b-g.webp"
            />
            <TeamCard
              name="Tizian Groß"
              role="Co-Founder"
              workRole="Designer"
              image="team-t-g.webp"
            />
            <TeamCard
              name="Marcel Mertens"
              role="Co-Founder"
              workRole="Backend Developer"
              image="team-m-m.webp"
            />
          </div>
        </div>
      </section>
    </div>
    <section class="section-spacer"></section>
  </div>
</template>

<script>
import TeamCard from "./sub-components/TeamCard.vue";

export default {
  name: "Team",
  components: { TeamCard },
};
</script>

<style scoped>
.diagonal-box {
  background-image: linear-gradient(45deg, #6303b1b4, #ff730071);
  transform: skewY(-2deg);
  padding: 4.2rem 0;
}

.content-team {
  transform: skewY(2deg);
}

.fade-in {
  opacity: 0;
  transform: translateY(10px);
  transition: all 1500ms ease-out;
}

.fade-in.appear {
  opacity: 1;
  transform: translateY(0px);
}
</style>
