<template>
  <div id="about">
    <section class="section">
      <div v-scrollAnimation class="container">
        <h2 class="title is-2 is-size-4-touch has-text-centered">
          Wir helfen beim Schritt in die Digitalisierung.
        </h2>
        <p
          class="
            subtitle
            is-4 is-size-5-touch
            has-text-grey-3 has-text-centered
          "
        >
          Mit Hilfe von Webseiten und Mobile-Apps
        </p>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column">
            <figure class="image is-4by5">
              <img
                class="cover-image"
                src="@/assets/processed/desktop-cover.webp"
                :srcset="this.imageSrcSet('desktop-cover.webp')"
                alt="Beispiele für unser Webseiten Design"
              />
            </figure>
          </div>
          <div class="column has-text-left ml-0 mt-3">
            <h2 class="title is-2 is-size-3-touch has-text-gray">Webseiten</h2>
            <p class="subtitle is-4 is-size-5-touch has-text-grey-dark mt-3">
              Wir erstellen für Ihr Unternehmen eine individuelle, moderne
              Website, die Ihre Webpräsenz auffrischt
            </p>
            <p class="has-text-grey is-size-5 is-size-6-touch mt-3">
              Wir erstellen nach Ihren Wünschen eine individuelle Website für
              Ihre Projekte. Mit langjähriger Erfahrung im Bereich der
              Webseitengestaltung sind wir ein verlässlicher Partner und freuen
              uns auf Herausforderungen jeglicher Art. Unser Hauseigenes Design
              Team entwickelt zusammen mit Ihnen ein individuelles Designkonzept
              für Ihre Webseite. Unsere professionellen Webentwickler setzen
              dieses Designkonzept dann als funktionale Webseite nach Ihrem
              Belieben um. Wenn sie den vollen Service genießen wollen,
              übernehmen wir das Hosting und die Wartung ihrer Webseite - Wir
              bieten damit einen Rundumsorglos Service.
              <br />
              <br />
              Eine aktuelle
              <router-link to="prices">Preisliste</router-link> gibt Ihnen einen
              Überblick über die Kosten unserer Dienstleistungen. Bei Fragen
              kontaktieren Sie uns gerne über unser
              <router-link to="/contact">Kontaktformular</router-link>.
              <br />
              <br />
              Um einen Überblick über unsere bisherigen Projekte zu bekommen,
              gelangen sie hier zu unserem Portfolio.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column has-text-left mt-3">
            <h2 class="title is-2 is-size-3-touch has-text-gray">
              Mobile-Apps
            </h2>
            <p class="subtitle is-4 is-size-5-touch has-text-grey-dark mt-3">
              Neben der Entwicklung von Webseiten bieten wir die Entwicklung
              nativer Anwendungen für iOS und Android an.
            </p>
            <p class="has-text-grey is-size-5 is-size-6-touch mt-3">
              Flexrent ist eine mobile Anwendung, die den Markt des privaten
              Mietens und Vermietens für immer verändert. Mit unserer Plattform
              bieten wir Privatpersonen die Möglichkeit, Geld mit herumliegenden
              Gegenständen zu verdienen. Die Sharing Economy macht die Nutzung
              selten gebrauchter Gegenstände nachhaltiger.

              <br />
              <br />
              Auch für Ihr Unternehmen können wir eine passende App erstellen.
              Dazu verwenden wir moderne Frameworks und individuelle Designs.

              <br />
              <br />
              Für ein individuelles Angebot kontaktieren Sie uns gerne über
              unser <router-link to="/contact">Kontaktformular</router-link>.
            </p>
          </div>
          <div class="column">
            <figure class="image is-4by5">
              <img
                class="cover-image"
                src="@/assets/processed/mobile-cover.webp"
                :srcset="this.imageSrcSet('mobile-cover.webp')"
                alt="Flexrent app screenshots"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>

    <section class="section-spacer"></section>
  </div>
</template>

<script>
export default {
  name: "About",
  components: {},
  methods: {
    imageSrcSet(filename) {
      const fileNameArr = filename.split(".");
      const imageBaseName = fileNameArr[0];

      return `${require(`@/assets/processed/${imageBaseName}-200x.webp`)} 200w,
        ${require(`@/assets/processed/${imageBaseName}-360x.webp`)} 360w,
        ${require(`@/assets/processed/${imageBaseName}-480x.webp`)} 480w,
        ${require(`@/assets/processed/${imageBaseName}-640x.webp`)} 640w,
        ${require(`@/assets/processed/${imageBaseName}-720x.webp`)} 720w,
        ${require(`@/assets/processed/${imageBaseName}-768x.webp`)} 768w,
        ${require(`@/assets/processed/${imageBaseName}-900x.webp`)} 900w,
        ${require(`@/assets/processed/${imageBaseName}-1080x.webp`)} 1080w,
        ${require(`@/assets/processed/${imageBaseName}-1200x.webp`)} 1200w,
        ${require(`@/assets/processed/${imageBaseName}-1280x.webp`)} 1280w,
        ${require(`@/assets/processed/${imageBaseName}-1440x.webp`)} 1440w,
        ${require(`@/assets/processed/${imageBaseName}-1920x.webp`)} 1920w,
        ${require(`@/assets/processed/${imageBaseName}-2160x.webp`)} 2160w,
        ${require(`@/assets/processed/${imageBaseName}-2220x.webp`)} 2220w,
        ${require(`@/assets/processed/${imageBaseName}-2560x.webp`)} 2560w,
        ${require(`@/assets/processed/${imageBaseName}-2960x.webp`)} 2960w,
        ${require(`@/assets/processed/${imageBaseName}-3040x.webp`)} 3040w,
      `;
    },
  },
};
</script>

<style scoped>
.cover-image {
  border-radius: 1%;
}

.fade-in {
  opacity: 0;
  transform: translateY(10px);
  transition: all 1500ms ease-out;
}

.fade-in.appear {
  opacity: 1;
  transform: translateY(0px);
}
</style>
