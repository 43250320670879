<template>
  <footer class="footer mt-6">
    <div class="content has-text-centered">
      <div class="columns is-mobile is-multiline has-text-left is-centered">
        <div class="column is-4-desktop is-12-touch">
          <div class="is-12 mb-4">
            <figure class="image is-48x48 ml-0">
              <img
                src="../assets/processed/logo.webp"
                alt="Straight and Curved Logo"
              />
            </figure>
          </div>

          <p class="is-size-7 has-text-grey">
            Copyright © 2021 - {{ currentYear() }} Straight and Curved UG (haftungsbeschränkt). All
            rights reserved.
          </p>

          <div>
            <p class="is-size-7 has-text-grey">
              <router-link to="/imprint">Impressum</router-link>
            </p>
            <p class="is-size-7 has-text-grey">
              <router-link to="/data-protection"
                >Datenschutzerklärung</router-link
              >
            </p>
          </div>
        </div>
        <div class="column is-6-desktop is-12-touch footer-links">
          <div class="columns is-mobile is-multiline">
            <!-- <div class="column is-4-desktop is-12-touch">
              <p class="has-text-weight-medium title is-4 has-text-gray">
                Produkte
              </p>
              <p class="is-size-5"><a href="#">Flexrent</a></p>
              <p class="is-size-5"><a href="#">Website</a></p>
            </div>
            <div class="column is-4-desktop is-12-touch">
              <p class="has-text-weight-medium title is-4 has-text-gray">
                Über uns
              </p>
              <p class="is-size-5"><a href="#">Team</a></p>
              <p class="is-size-5"><a href="#">Preise</a></p>
            </div> -->
            <div class="column is-8-desktop is-12-touch"></div>

            <div class="column is-4-desktop is-12-touch">
              <p class="has-text-weight-medium title is-4 has-text-gray">
                Social Media
              </p>
              <div class="social">
                <a
                  href="https://twitter.com/straight_curved"
                  target="_blank"
                  aria-label="Twitter"
                  ><font-awesome-icon :icon="['fab', 'twitter']" size="lg"
                /></a>
                <a
                  href="https://www.linkedin.com/company/straight-and-curved"
                  target="_blank"
                  aria-label="LinkedIn"
                >
                  <font-awesome-icon :icon="['fab', 'linkedin-in']" size="lg"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    currentYear() {
      return (new Date()).getFullYear()
    }
  }
};
</script>

<style>
.social a {
  padding: 0.69rem;
}

.footer-links a {
  color: #7a7a7a;
}
</style>
