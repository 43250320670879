<template>
  <div class="column is-one-quarter-desktop is-half-tablet">
    <div class="card">
      <div class="card-image">
        <figure class="image is-4by3">
          <img
            class="cover"
            :src="require(`@/assets/processed/team/${image}`)"
            :srcset="this.imageSrcSet"
            alt="Teammitglied"
          />
        </figure>
      </div>
      <div class="card-content has-text-left">
        <p
          class="
            title
            is-size-5-touch
            is-size-6-desktop
            is-size-5-widescreen
            is-size-5-fullhd
          "
        >
          {{ name }}
        </p>

        <div class="subtitle">
          <p
            class="
              is-size-5-touch
              is-size-6-desktop
              is-size-5-widescreen
              is-size-5-fullhd
              has-text-grey
            "
          >
            {{ role }}
          </p>
          <p
            class="
              is-size-5-touch
              is-size-6-desktop
              is-size-5-widescreen
              is-size-5-fullhd
              has-text-info
            "
          >
            {{ workRole }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    role: String,
    workRole: String,
    image: String,
  },
  computed: {
    imageSrcSet () {
    const fileNameArr = this.image.split('.')
    const imageBaseNameSplit = fileNameArr[0]

    return `${require(`@/assets/processed/team/${imageBaseNameSplit}-200x.webp`)} 200w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-360x.webp`)} 360w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-480x.webp`)} 480w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-640x.webp`)} 640w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-720x.webp`)} 720w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-768x.webp`)} 768w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-900x.webp`)} 900w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-1080x.webp`)} 1080w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-1200x.webp`)} 1200w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-1280x.webp`)} 1280w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-1440x.webp`)} 1440w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-1920x.webp`)} 1920w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-2160x.webp`)} 2160w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-2220x.webp`)} 2220w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-2560x.webp`)} 2560w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-2960x.webp`)} 2960w,
      ${require(`@/assets/processed/team/${imageBaseNameSplit}-3040x.webp`)} 3040w,
    `
    },
  }
};
</script>

<style scoped>
.cover {
  object-fit: cover;
  object-position: 100% 33%;
}

.image {
  margin: 0;
}
</style>
